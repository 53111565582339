import React from 'react'
import { css } from '@emotion/core'

import {
  BLACK,
  BLUE,
  BLUE_DARK,
  GRAY_DARKER,
  GRAY_MEDIUM_LIGHT,
  GREEN,
  ORANGE,
  PURPLE,
  PURPLE_DARK,
  PURPLE_LIGHT,
  YELLOW,
  YELLOW_DARK
} from '../../../constants/theme.styles'

const baseLabelStyles = css`
  display: inline-block;
  padding: 0.5833em 0.833em;
  line-height: 1;
  vertical-align: baseline;
  text-transform: none;
  font-weight: 600;
  border-radius: 0.4rem;
  transition: background-color 0.1s ease;
`

const circularStyles = css`
  background: ${GRAY_MEDIUM_LIGHT};
  text-align: center;
  border-radius: 50%;
  padding: 0.5em;
  width: 2em;
  line-height: 1em;
`

const uppercaseStyles = css`
  text-transform: uppercase;
`

const nowrapStyles = css`
  white-space: nowrap;
`

const roundedStyles = css`
  border-radius: 100px;
`

// SIZE STYLES
export type Size =
  | 'massive'
  | 'huge'
  | 'big'
  | 'large'
  | 'medium'
  | 'small'
  | 'tiny'
  | 'mini'
  | 'micro'

interface ISizeMap {
  massive: number
  huge: number
  big: number
  large: number
  medium: number
  small: number
  tiny: number
  mini: number
  micro: number
}

const sizeMap: ISizeMap = {
  massive: 2.9,
  huge: 2.3,
  big: 2.0,
  large: 1.6,
  medium: 1.4,
  small: 1.2,
  tiny: 1.1,
  mini: 0.9,
  micro: 0.2
}

const sizeStyles = (size: Size) => {
  return css`
    font-size: ${sizeMap[size]}rem;
  `
}

// COLOR STYLES
export type LabelColors =
  | 'white'
  | 'light'
  | 'dark'
  | 'black'
  | 'blue'
  | 'purple'
  | 'purpleLight'
  | 'purpleDark'
  | 'yellow'
  | 'orange'
  | 'green'

const colorStylesMap = {
  white: {
    background: '#fff',
    color: BLACK
  },
  light: {
    background: '#e8e9f0',
    color: BLACK
  },
  dark: {
    background: GRAY_DARKER,
    color: '#fff'
  },
  black: {
    background: BLACK,
    color: '#fff'
  },
  blue: {
    background: BLUE,
    color: '#fff'
  },
  purple: {
    background: PURPLE,
    color: '#fff'
  },
  purpleLight: {
    background: PURPLE_LIGHT,
    color: PURPLE_DARK
  },
  purpleDark: {
    background: PURPLE_DARK,
    color: '#fff'
  },
  yellow: {
    background: YELLOW,
    color: BLACK
  },
  orange: {
    background: ORANGE,
    color: BLACK
  },
  green: {
    background: GREEN,
    color: BLACK
  }
}

const colorStyles = (color: LabelColors) => {
  return css`
    background: ${colorStylesMap[color].background};
    color: ${colorStylesMap[color].color};
    :hover {
      color: ${colorStylesMap[color].color};
    }
  `
}

// Anchor and button styles
interface IColors {
  white: string
  light: string
  dark: string
  black: string
  blue: string
  purple: string
  purpleLight: string
  purpleDark: string
  yellow: string
  orange: string
  green: string
}

const hoverColors: IColors = {
  white: '#efefef',
  light: '#f1f1f3',
  dark: '#333333',
  black: '#333333',
  blue: BLUE_DARK,
  purple: PURPLE,
  purpleLight: PURPLE_LIGHT,
  purpleDark: PURPLE_DARK,
  yellow: YELLOW_DARK,
  orange: ORANGE,
  green: GREEN
}

const hoverableStyles = (color: LabelColors) => {
  return css`
    cursor: pointer;
    transition: all 0.3s ease;
    &:active,
    &:focus {
      outline: none;
    }
    &:hover {
      background: ${hoverColors[color]};
    }
  `
}

type TagName = 'div' | 'span' | 'a' | 'button'

interface ILabelProps {
  as?: TagName
  size?: Size
  circular?: boolean
  color?: LabelColors
  rounded?: boolean
  uppercase?: boolean
  nowrap?: boolean
  children?: React.ReactNode
}

interface ILabelAsAnchorProps extends ILabelProps {
  as: 'a'
}

interface ILabelAsButtonProps extends ILabelProps {
  as: 'button'
}

interface ILabelAsDivProps extends ILabelProps {
  as?: 'div' | 'span'
}

type anchorAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>
type buttonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>

function Label(props: anchorAttributes & ILabelAsAnchorProps): JSX.Element
function Label(props: buttonAttributes & ILabelAsButtonProps): JSX.Element
function Label(props: ILabelAsDivProps): JSX.Element

function Label({
  as: Element = 'div',
  size,
  circular,
  color = 'light',
  rounded,
  uppercase,
  nowrap,
  children,
  ...rest
}: ILabelProps) {
  return (
    <Element
      css={[
        baseLabelStyles,
        circular ? circularStyles : null,
        size ? sizeStyles(size) : null,
        color ? colorStyles(color) : null,
        rounded ? roundedStyles : null,
        uppercase ? uppercaseStyles : null,
        nowrap ? nowrapStyles : null,
        Element === 'a' || Element === 'button' ? hoverableStyles(color) : null
      ]}
      {...rest}
    >
      {children}
      {!children && <span>&nbsp;</span>}
    </Element>
  )
}

export default Label
